import React from "react"

import { PageLayout } from "../components/layouts"
import SEO from "../components/seo"

import TextBlock from "../components/textBlock"
import SingleColumnBlock from "../components/singleColumnBlock"
import Blockquote from "../components/blockquote"

import ActiveItemGallery from "@components/ActiveItemGallery"

import {
  ContactForm,
  FormWrapper,
  RequestMoreInfo,
  RequestQuote,
} from "@components/Forms"

import {
  RequestMoreInfoButton,
  ContactUsTodayButton,
  RequestAQuoteButton,
} from "../components/buttons"

const galleryItems = [
  {
    key: 1,
    title: "Pelican's Nest Golf Club",
    images: ["/images/projects/pelicans-nest-project1.jpg"],
  },
  {
    key: 2,
    title: "Pelican's Nest Golf Club",
    images: ["/images/projects/pelicans-nest-project2.jpg"],
  },
  {
    key: 3,
    title: "Pelican's Nest Golf Club",
    images: ["/images/projects/pelicans-nest-project3.jpg"],
  },
].map(m => ({
  ...m,
  listItems: [
    {
      title: "Project",
      value: "Pelican's Nest Golf Club at Pelican Landing, Bonita Springs, FL",
    },
    {
      title: "Product Details",
      value:
        '1/4" Low Iron Laminated Reed patterned glass with .060" EVA Gold Glitter interlayer',
    },
    {
      title: "Glazing Contractor",
      value: "Glass Design (www.glassdesigns.com), Naples, FL",
    },
  ],
}))

const PatternGlassPelicansNestGolfClubBonitaSpringsFl = () => {
  return (
    <PageLayout>
      <SEO
        title="Custom Glass Bonita Springs FL | Pelican’s Nest Golf Club | ArtVue Glass"
        ogDescription="Custom, one-of-a-kind glass partitions made to your specifications. Call (941) 378-1762 for a free consultation on how we can transform your space into a work of art."
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h1>
              Pelican’s Nest Golf Club at Pelican Landing, Bonita Springs, FL
            </h1>
            <p>
              Gold glitter and reeded pattern glass add luxury and sparkle to
              this glass partition—a perfect transition between the columned
              hallway, dining area, and grand piano.
            </p>
          </div>
        }
      />
      <ActiveItemGallery items={galleryItems} galleryTitle="Project images:" />
      <SingleColumnBlock
        textPadded
        text={
          <div>
                        <FormWrapper
              modal
              buttonProps={{
                content: "Request A Quote",
                size: "tiny",
                icon: "file pdf outline",
              }}
            >
              <RequestQuote />
            </FormWrapper>
          </div>
        }
      />
    </PageLayout>
  )
}

export default PatternGlassPelicansNestGolfClubBonitaSpringsFl
